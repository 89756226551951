<template>
    <button @click="show = true" class="btn ml-2 btn-success btn-sm">
        <i class="fa fa-upload"></i> Tải dữ liệu
        <b-modal v-model="show" title="Tải danh sách can thiệp chăm sóc" @close="close" no-close-on-esc no-close-on-backdrop
                 no-enforce-focus>
            <div class="form-group">
                <label><b>Tải xuống tập tin mẫu</b></label>
                <div>
                    <button @click="downloadTemplate" class="btn btn-sm btn-secondary mr-1 mb-2">
                        <i class="fa fa-file-excel"></i> {{templateName}}
                    </button>
                </div>
            </div>
            <div class="form-group">
                <label><b>Chọn tập tin</b></label>
                <b-form-file v-model="file" :disabled="reading||totalRows>0"
                             placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             drop-placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             browse-text="Chọn tập tin" 
                             accept="application/x-iwork-keynote-sffnumbers,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></b-form-file>
            </div>
            <b-form-checkbox v-model="updated" value="1" unchecked-value="0">
                Cho phép cập nhật thông tin khi trùng mã người khuyết tật và ngày can thiệp gần nhất
            </b-form-checkbox>
            <div class='mb-3' ></div>
            <div v-if="reading" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang đọc file. Quá trình này có thể sẽ tốn nhiều thời gian nếu file tải lên có dung lượng lớn. Xin đừng tắt cửa sổ trình duyệt cho đến khi quá trình tải lên hoàn tất!</span>
            </div>
            <div v-if="importing" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang tải dữ liệu lên... Xin hãy kiên nhẫn, việc tải lên sẽ mất một chút thời gian nếu file của bạn quá lớn</span>
            </div>
            <div class="mb-3" v-if="totalRows">
                <b-progress :max="totalRows">
                    <b-progress-bar :value="successCount+errorCount"
                                    :label="`${(((successCount + errorCount) / totalRows) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
                <div class="text-center mt-2">Xử lý {{ successCount + errorCount }}/{{ totalRows }} dòng | <span :class="[errorCount && errorCount > 0? 'text-danger':'']" >Vấn đề: {{ errorCount }} </span>
                    <a v-if='errorCount > 0' @click="downloadErrors" class="btn-link text-primary" href="javascript:;">[Chi tiết]</a>
                </div>
            </div>
            <template v-slot:modal-footer>
                <button class="btn btn-secondary" @click="cancel" v-if="!reading&&importing">Dừng tải</button>
                <button class="btn btn-primary" @click="reset" v-if="!reading&&!importing&&totalRows>0">Làm lại</button>
                <button v-if="totalRows===0" class="btn btn-primary" @click="process" :disabled="reading||importing">
                    <b-spinner small v-if="reading||importing"></b-spinner>
                    Tải lên
                </button>
            </template>
        </b-modal>
    </button>
</template>

<script>
    import Import from "@/mixins/Import";
    import {saveAs} from 'file-saver'
    import Excel from 'exceljs';

    export default {
        props: ['params'],
        mixins: [Import],
        data() {
            return {
                service: '/province',
                fileName: `province`,
                templateName: 'dia-diem-code',
                reloadBus: "reloadProvince",
                lozbus:"reload",
                headers: [
                    {header: 'ward_id', key: 'ward_id',label: 'Phường', width: 30},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện', width: 30},
                    {header: 'name', key: 'name', label: 'Tên phường xã', width: 30},
                    {header: 'position', key: 'position', label: 'Số thứ tự', width: 30},
                    {header: 'type', key: 'type', label: 'Kiểu', width: 30},
                    {header: 'code', key: 'code', label: 'Mã', width: 30},
                ],
                indexRow: 4,
            }
        },
        methods: {
            downloadTemplate: function () {
                let workbook = new Excel.Workbook();
                let sheet = workbook.addWorksheet('Dữ liệu');
                let cols = [];
                let row = {};
                for (let i in this.headers) {
                    let header = this.headers[i];
                    cols.push(header);
                    row[header.key] = header.label;
                }
                sheet.columns = cols;
                sheet.addRow(row);
                row = {};
                row["ward_id"] = "Nhập định dạng chữ"
                row["district_id"] = "Nhập định dạng chữ";
                row["name"] = "Nhập định dạng chữ";
                row["position"] = "Nhập định dạng chữ";
                row["type"] = "Nhập định dạng chữ";
                row["code"] = "Nhập định dạng chữ";
                sheet.addRow(row);
                row.alignment = {wrapText: true};
                //ẩn dòng 1
                sheet.getRow(1).hidden = true;
                sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'bdbdbd'
                    }
                };

                // đôi đậm và wrap text
                sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(2).font = {
                    bold: true
                };
                //bôi màu
                sheet.getRow(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '92D050'
                    }
                };
                //Dòng 3
                sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(3).font = {
                    italic: true
                };
                workbook.xlsx.writeBuffer({base64: true}).then(data => {
                    saveAs(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                            this.templateName);
                });
            },
            async save(item) {
                if (this.updated == 1) {
                    await this.$service.post(`${this.service}/import-code?t=u`, item);
                } else {
                    await this.$service.post(`${this.service}/import-code?t=c`, item);
                }
            },
            async getItem(headerRow, row) {
                let item = {};
                for (let j = 1; j <= headerRow.cellCount; j++) {
                    let key = headerRow.getCell(j).value;
                    let value;
                    if (row.getCell(j).isHyperlink) {
                        value = row.getCell(j).value.text;
                    } else {
                        value = row.getCell(j).value;
                    }
                    if (headerRow.getCell(1).value !== 'ward_id' || headerRow.getCell(2).value !== 'district_id' || headerRow.getCell(6).value !== 'code') {
                        throw new Error("File tải lên chưa đúng mẫu!");
                    }
                    if (value !== null && value !== '') {
                        if (typeof value === 'string') {
                            value = value.trim();
                        }
                        item[key] = value;
                    } else {
                        item[key] = null;
                    }

                }
                return item;
            }
        }
    }
</script>